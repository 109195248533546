import moment from 'moment'

export const PROMOTION_PARAMS = [
  {
    type: 6,
    is_active: { editable: false, presetValue: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    users: { editable: true, required: true },
    value_type: { presetValue: 1, hide: true },
    amount: { editable: true, required: true, minValue: 1 },
  },
  {
    type: 11,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeDefault: 2 },
    amount: { editable: false, required: false, presetValue: null, hide: true },
  },
  {
    type: 12,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    expired_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: false, presetValue: 2, hide: true },
    amount: { editable: true, required: true, minValue: 1 },
  },
  {
    type: 13,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    expired_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: false, presetValue: 2, hide: true },
    devices: { editable: true, required: true },
    amount: { editable: true, required: true, minValue: 1 },
  },
  {
    type: 14,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    expired_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: false, presetValue: 2, hide: true },
    service_points: { editable: true, required: true },
    amount: { editable: true, required: true, minValue: 1 },
  },
  {
    type: 15,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    expired_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeOnly: 2 },
    value_type: { editable: false, presetValue: 2, hide: true },
    amount: { editable: false, required: false, presetValue: null, hide: true },
  },
  {
    type: 16,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    expired_at: { editable: true, required: true, presetValue: moment(new Date()).format('YYYY-MM-DD') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: false, presetValue: 2, hide: true },
    amount: { editable: true, required: true, minValue: 1 },
  },
]
